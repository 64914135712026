@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Rajdhani:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aldrich&family=Anta&family=Kode+Mono:wght@400..700&family=Michroma&family=Stick+No+Bills:wght@200..800&display=swap');
@property --myHue {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

:root {
  --primary-color: #FFF;
  --secondary-color: #FFD700;
  --text-color: #FFD700;
  --secondary-text-color: #FFFFE0;
  --heading-color: #FFD700;
  --link-color: #e74c3c;
  --link-hover-color: #c0392b;
  --primary-font: "Noto Sans", sans-serif;
  --secondary-font: "Rajdhani", sans-serif;
  --stick-font:"Stick No Bills", sans-serif;
  --anta-font:"Anta", sans-serif;
  --aldrich-font:"Aldrich", sans-serif;
  --kode-font:"Kode Mono", monospace;
}

body.dark-theme {
  /* Dark Theme Colors */
  --primary-color: #fff;
  --secondary-color: #aaaaaa;
  --text-color: #ecf0f1;
  --secondary-text-color: #95a5a6;
  --heading-color: #ecf0f1;
  --link-color: #e74c3c;
  --link-hover-color: #c0392b;
  --background-color: #2c3e50;
  --primary-font: "Noto Sans", sans-serif;
  --secondary-font: "Rajdhani", sans-serif;
}

/* src/App.css */
body {
  font-family: var(--kode-font) !important;
  font-weight: 200;
  font-style: normal;
  min-height: 100dvh;
  /* padding: 50px; */
  color: var(--secondary-text-color) !important;
  overflow: hidden;
}
body:before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('./assets/herobg.png');
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  /* filter: hue-rotate(var(--myHue));  */
  /* animation: hue-rotate 180s infinite linear; */
}

@keyframes hue-rotate {
  to{
      --myHue: 360deg;
  }
}

#root{
  padding:2rem;
  backdrop-filter: blur(2px);
}

.App::-webkit-scrollbar {
  display: none;
}

.App {
  box-sizing: border-box;
  width: 100%;
  height:calc(100vh - 100px);
  display: flex;
}
.logo{
  width: 100px;
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: contain;
}
.bg-img{
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
animation:  pulseBrightness 15s ease-in-out infinite;
}
@keyframes rotate360 {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes pulseBrightness {
  0%, 100% {
    filter: brightness(1.5);
  }
  50% {
    filter: brightness(0.5);
  }
}

ul {
  list-style-type: none;
}

.comp-global,
.education,
.skills,
.education,
.about-me,
.contact,
.projects {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; 
  align-items: end;
}

/* src/components/Header.css */
.header {
  position: absolute !important;
  align-items: unset !important;
  z-index: 99;
}


.header-container *{
  text-decoration:  none !important;
}
.name-header {
  font-weight: 500 !important;
  font-family: var(--secondary-font);
  font-size: 3.5rem;
  text-decoration: none !important;
  text-wrap: nowrap;
  line-height: 1;
  color: var(--primary-color);
}
.desig-header{
  color: var(--primary-color);
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: unset !important;
}

.navbar-collapse {
  align-items: unset !important;
}

.navbar-nav>a {
  text-decoration: none !important;
  color: var(--primary-color);
  padding-block: 1rem;
  max-width: fit-content !important;
}
.active-link{
  color: var(--secondary-color) !important;
}

.navbar-nav>a:hover {
  color: var(--secondary-color);
}
.name-as-object{
  font-family: var(--secondary-font) !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  letter-spacing: 1px;
}
.download-cv{
  border-color: var(--secondary-text-color) !important;
  color: var(--secondary-text-color) !important;
}
.download-cv:hover{
  color: var(--primary-color) !important;
  font-weight: bold;
  border-color: var(--secondary-color) !important;
  background-color: var(--secondary-color) !important;
}

/* experience */
.experience {
  overflow-y: scroll;
}


.experience-header, .education-header{
  font-size: 2rem;
  font-weight: 600;
}
.aboutme-header, .contact-header{
  font-size: 3rem;
  font-family: var(--secondary-font);
  font-weight: 500;
  margin-bottom: 3rem;
  color: var(--primary-color);
}
.about-me-div{
  text-align: end;
}
.job{
  text-align: end;
}
.job h3{
  color: var(--primary-color);
}

/* Skills  */
.skills {
  padding: 2rem;
  overflow: hidden;
  width: 100%;
  position: relative;
}

h2 {
  text-align: center;
  margin-bottom: 2rem;
  color: #333; /* Optional: Adjust text color */
}

.marquee {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 50%;
  min-height: 82px;
}

.marquee-content {
  display: flex;
  animation: marquee 20s linear infinite;
  animation-delay: 1s; /* Delay to allow animation to start smoothly */
  width: 200%; /* Double the width for the continuous scrolling effect */
}

.skill-item {
  display: inline-block;
  padding: 1rem;
  transition: transform 0.3s;
  cursor: pointer;
}
.skill-item img{
  filter: brightness(1);
  aspect-ratio: 3 / 2;
  object-fit: contain;
  mix-blend-mode: color-burn;
}
.skill-item:hover img{
  filter: brightness(1.2);
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.342));
}

.skill-item:hover {
  transform: scale(1.2);
}

/* Pause animation on hover */
.marquee:hover .marquee-content {
  animation-play-state: paused;
}

/* Gradient fade effect */
.marquee::before,
.marquee::after {
  content: "";
  position: absolute;
  top: 0;
  width: 20%; /* Width of the fade effect */
  height: 100%;
  pointer-events: none;
}

.marquee::before {
  left: 0;
  background: linear-gradient(to right, rgb(5 8 22), rgb(141 139 139 / 0%));
  /* rotate: 180deg; */
  opacity: 1;
  z-index: 1
}

.marquee::after {
  right: 0;
  background: linear-gradient(to left, rgba(7,11,28), rgba(139,140,141, 0));
}

@keyframes marquee {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(3%);
  }
}
.skill-name{
  display: none;
}

.profile-pic{
  width: 250px;
  aspect-ratio: 1;
  border-radius: 50%;
}
.summary{
  text-align: center;
}
.greet{
  font-size: 1.5rem;
  font-weight: bold;
}
.name{
  font-size: 2.5rem;
  font-weight: bolder;
}
.desig{
  font-size: 1.5rem;
  font-weight: bold;
}
.projects-title,.skills-title{
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: 700;
  color: var(--primary-color);
}
.project-links, .contact-link{
  /* text-decoration: none; */
  color: inherit;
  font-weight: 600;
}
.project-links:hover, .contact-link:hover {
  color: var(--secondary-color);
}
.contact-link{
  display: flex;
    justify-content: end;
    align-items: center;
    padding-block: 0.15rem;
}
.company-name, .client-name{
  font-size: 14px;
  color: var(--primary-color);
}
 .project-div{
  height: 80%;
  overflow-y: auto;
  scrollbar-width: none;
 }
 .oops-image{
  width: 50%;
  height: 50%;
 }
.go-back-btn{
  margin-right: 18%;
}



.circle-container {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 0 auto;
  /* animation: rotateText 60s linear infinite; */
}

.circle-text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -50%;
}

.circle-text span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: 0 150px; /* Adjust radius here */
  transform: rotate(calc(var(--i) * 24deg)) translateX(-50%);
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}
@keyframes rotateText {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

/* Assign unique colors to each word */
.circle-text span:nth-child(1)  { --i: 0;  color: #E34F26; }  /* HTML */
.circle-text span:nth-child(2)  { --i: 1;  color: #1572B6; }  /* CSS */
.circle-text span:nth-child(3)  { --i: 2;  color: #F7DF1E; }  /* JavaScript */
.circle-text span:nth-child(4)  { --i: 3;  color: #61DAFB; }  /* ReactJs */
.circle-text span:nth-child(5)  { --i: 4;  color: #59c759; }  /* NextJs */
.circle-text span:nth-child(6)  { --i: 5;  color: #663399; }  /* GatsbyJs */
.circle-text span:nth-child(7)  { --i: 6;  color: #38B2AC; }  /* Tailwind */
.circle-text span:nth-child(8)  { --i: 7;  color: #00488D; }  /* CMS */
.circle-text span:nth-child(9)  { --i: 8;  color: #E37400; }  /* GA4 */
.circle-text span:nth-child(10) { --i: 9;  color: #F05033; }  /* Git */
.circle-text span:nth-child(11) { --i: 10; color: #3178C6; }  /* TypeScript */
.circle-text span:nth-child(12) { --i: 11; color: #563D7C; }  /* Bootstrap */
.circle-text span:nth-child(13) { --i: 12; color: #CB3837; }  /* NPM */
.circle-text span:nth-child(14) { --i: 13; color: #0052CC; }  /* JIRA */
.circle-text span:nth-child(15) { --i: 14; color: #339933; }  /* NodeJs */

.profile-pic {
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.profile-div{
  position: relative;
  height: 22rem;  /*if you remove tech stack circle*/
}
.mandala-container{
  position: absolute;
   top: 68%;
   left: -17%;
   transform: translate(-50%, -50%);
   animation: rotateText 60s linear infinite;
 }
 .mandala-container svg>text {
   fill: var(--mandala-primary);
 }
 .mandala-container *{
 
   fill: #ffffff33 !important;
  
 }
 




 /* Tab and Mobile responsive media query  */
 @media (max-width: 768px) {
  body{
    padding: 20px;
  }
  .App{
    height: calc(100dvh - 50px);
    display: flex;
    align-items: end;
  }
  .header {
    left: 0;
    right: 0;
    width: 100%;
    padding: 20px !important;
  }
  .bg-img{
    width: 50%;
    z-index: -1;
    opacity: 0.4;
  }

  .navbar-collapse {
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  .name-header{
    font-size: 1.5rem !important;
    color: var(--primary-color);
  }
  .header-container{
    justify-content: unset !important;
  }
  .navbar-nav > a {
    padding-block: 0.25rem !important;
  }
  .download-cv{
    margin-top: 10px;
  }
  .projects{
    justify-content: unset;
    height: 60%;
    overflow-y: scroll;
  }
  .projects::-webkit-scrollbar{
    display: none;
  }
  .profile-pic{
    width: 180px !important;
  }
  .name{
    font-size: 2rem !important;
  }
  .about-me{
    text-align: end;
  }
  .marquee {
    width: 100%;
    }
    .navbar-nav{
      padding-top:1rem ;
    }
    .skill-name{
      font-size: 12px;
      display: flex;
      justify-content: center;
    }
    .project-div{
      height: 100%;
    }
    .oops-image{
      width: 50%;
      height: 25%;
     }
     .go-back-btn{
      margin-right: 10%;
    }
    .circle-text span {
      transform-origin: 0 120px; /* Adjust radius here */
    }
    .circle-text{
      top:-40%;
    }
}
@media (max-width:500px){
  #root{
    padding:0.5rem;
  }
  body,.App{
    padding: unset;
  }
  .desig, .download-cv{
    font-size: 12px !important;
  }
  .circle-container{
    width: 250px;
    height: 250px;
  }
  .circle-text{
    top:-48%;
  }
  .name{
    font-size:1rem !important;
  }
  .greet{
    font-size: 0.7rem;
  }
  .projects-title,.skills-title,.aboutme-header,.contact-header{
    font-size:2rem;
  }
  .about-div{
    overflow-y: scroll;
    padding-top: 20px;
    scrollbar-width: none
  }
  .about-me{
    height: 60vh;
  }
  .aboutme-header{
    margin-bottom: 1rem;
  }
  pre{
    margin-bottom: unset;
  }
  .navbar-nav {
    padding-top: unset;
  }
  .mandala-container{
    top: 80%;
    left: -39%;
  }
  .mandala-container svg{
    width: 450px;
    height: 450px;
  }
  .profile-div{
    height: 11rem;
  }
  .logo{
    width: 50px;
  }

   
}

